import { Blockfrost, Lucid, C as LCore } from 'lucid-cardano';

import { store } from '../../redux/store';
import { setWalletAssetInfo } from '../../redux/features/walletSlice';
import axios from 'axios';

import * as BIP39 from "bip39";

import buffer from 'buffer';

import { devmode } from '../../config';

import { setUserInfo } from '../../redux/features/userSlice';

const _Buffer = buffer.Buffer;

// export const socket = new WebSocket(`${process.env.REACT_APP_STREAMKEY_WSOCKET_URL}`);

// socket.onopen = () => {
//   console.log("onopen-->");
// }

// socket.onmessage = (e) => {
//   console.log("onmessage_e:", e);
//   let jsonData = JSON.parse(e.data);
//   console.log("jsonData:", jsonData);

//   if (jsonData.entries) store.dispatch(setEntry(jsonData.entries));
//   if (jsonData.game1) store.dispatch(setGame(jsonData.game1));
//   if (jsonData.game2) store.dispatch(setGame(jsonData.game2));
// }

export const getWalletInfo = async () => {
  try {
    const address = await lucid_user.wallet.address();
    const walletProvider = localStorage.getItem("wallet");
    
    // First get the StreamKey info with wallet provider info
    const streamKeyRes = await fetch(
      `${process.env.REACT_APP_STREAMKEY_API_URL}/content/address/${address}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Wallet-Provider': walletProvider
        }
      }
    );

    if (streamKeyRes.ok) {
      const streamKeyData = await streamKeyRes.json();
      
      const filteredContents = streamKeyData.contents.filter(
        content => content.walletAddress === address
      );

      store.dispatch(setUserInfo({
        user: streamKeyData.user,
        contents: filteredContents,
        status: streamKeyData.status,
        walletProvider: walletProvider,
        walletAddress: address
      }));
    }

    // Get UTXOs using Lucid
    const utxos = await lucid_user.wallet.getUtxos();
    const policy_ids = new Set();
    const streamkey_nfts = [];
    let lovelace_amount = 0;

    // Create a map to track processed policy IDs
    const processedPolicies = new Map();
    
    // First pass - collect all policy IDs and lovelace
    for (const utxo of utxos) {
      const assets = await utxo.assets;
      
      if (assets.lovelace) {
        lovelace_amount += Number(assets.lovelace);
      }

      for (const unit of Object.keys(assets)) {
        if (unit === 'lovelace') continue;
        
        const policyId = unit.slice(0, 56);
        
        // Only process each policy ID once
        if (!processedPolicies.has(policyId)) {
          policy_ids.add(policyId);
          processedPolicies.set(policyId, true);

          // Only fetch metadata for StreamKey NFTs
          if (policyId === process.env.REACT_APP_GEM_POLICY_ID || 
              policyId === process.env.REACT_APP_POWER_GEM_POLICY_ID) {
            try {
              const assetData = await lucid_user.provider.getAssetMetadata(unit);
              if (assetData) {
                streamkey_nfts.push({
                  assetId: unit,
                  name: assetData.name || 'Unknown',
                  image: assetData.image ? [assetData.image] : ['']
                });
              }
            } catch (error) {
              console.error('Error fetching NFT metadata:', error);
            }
          }
        }
      }
    }

    const finalPolicyIds = Array.from(policy_ids);

    store.dispatch(setWalletAssetInfo({
      address,
      streamkey_nfts,
      lovelace_amount,
      policy_ids: finalPolicyIds
    }));

  } catch (error) {
    console.error('Error in getWalletInfo:', error);
    store.dispatch(setWalletAssetInfo({
      address: '',
      streamkey_nfts: [],
      lovelace_amount: 0,
      policy_ids: []
    }));
  }
}

export const uploadToLocalStorage = async (file, walletAddress, onProgress) => {
    try {
        const folderName = walletAddress.slice(-4);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('walletAddress', walletAddress);
        formData.append('folder', folderName);

        const response = await fetch(`${process.env.REACT_APP_STREAMKEY_API_URL}/api/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Wallet-Provider': localStorage.getItem("wallet")
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        
        if (!result.path) {
            throw new Error('No path received from server');
        }

        return {
            success: true,
            data: {
                path: result.path
            }
        };
    } catch (error) {
        console.error('Upload error:', error);
        return { 
            success: false, 
            message: error.message 
        };
    }
};

export const blockFrost = new Blockfrost(
  process.env.REACT_APP_BLOCKFROST_API_URL,
  process.env.REACT_APP_BLOCKFROST_API_KEY
)

export const lucid = await Lucid.new(
  blockFrost,
  process.env.CADANO_NETWORK == 0 ? 'Testnet' : 'Mainnet'
)

export const lucid_user = await Lucid.new(
  blockFrost,
  process.env.CADANO_NETWORK == 0 ? 'Testnet' : 'Mainnet'
)

export const waitForTransaction = async (label, txHash) => {
  if (devmode) console.log(`Waiting for ${label} transaction to complete...`)
  if (devmode) console.log('txHash:', txHash)

  await blockFrost.awaitTx(txHash)

  console.log(`${label} transaction Completed!`)
}

export const createMintingPolicy = async () => {
  const wallet = lucid.wallet
  if (devmode) console.log(wallet);
  if (devmode) console.log(wallet.address());

  const { paymentCredential } = lucid.utils.getAddressDetails(
    await wallet.address(),
  )

  if (devmode) console.log("paymentCredential", paymentCredential);

  const script = lucid.utils.nativeScriptFromJson({
    type: 'all',
    scripts: [
      { type: 'sig', keyHash: paymentCredential.hash },
      {
        type: 'before',
        slot: lucid.utils.unixTimeToSlot(Date.now() + 46656000000),
      },
    ],
  })

  if (devmode) console.log("policyscript===========>", script);

  const policyId = lucid.utils.mintingPolicyToId(script)

  return {
    policyScript: script,
    policyId: policyId,
  }
}

export const getCurrentAccount = (mnemonic = process.env.MNEMONIC || "Something went wrong") => {

  const entropy = BIP39.mnemonicToEntropy(mnemonic);
  const rootKey = LCore.Bip32PrivateKey.from_bip39_entropy(
    Buffer.from(entropy, "hex"),
    Buffer.from(""),
  );

  const harden = (num) => { return 0x80000000 + num; }

  const accountKey = rootKey.derive(harden(1852)).derive(harden(1815)).derive(harden(0));
  const paymentKey = accountKey.derive(0).derive(0).to_raw_key();
  const stakeKey = accountKey.derive(2).derive(0).to_raw_key();

  const publicKey = Buffer.from(accountKey.to_public().as_bytes()).toString("hex"); // BIP32 Public Key
  const paymentKeyPub = paymentKey.to_public();
  const stakeKeyPub = stakeKey.to_public();

  const paymentKeyHash = Buffer.from(paymentKeyPub.hash().to_bytes(), "hex").toString("hex");
  const stakeKeyHash = Buffer.from(stakeKeyPub.hash().to_bytes(), "hex").toString("hex");

  // Base address with Staking Key
  const paymentAddr = LCore.BaseAddress.new(
    process.env.REACT_APP_CARDANO_NETWORK_ID == 0 ? LCore.NetworkInfo.testnet().network_id() : LCore.NetworkInfo.mainnet().network_id(),
    LCore.StakeCredential.from_keyhash(paymentKeyPub.hash()),
    LCore.StakeCredential.from_keyhash(stakeKeyPub.hash()),
  ).to_address().to_bech32();

  // Enterprise address without staking ability, for use by exchanges/etc
  const enterpriseAddr = LCore.EnterpriseAddress.new(
    process.env.REACT_APP_CARDANO_NETWORK_ID == 0 ? LCore.NetworkInfo.testnet().network_id() : LCore.NetworkInfo.mainnet().network_id(),
    LCore.StakeCredential.from_keyhash(paymentKeyPub.hash()),
  ).to_address().to_bech32();

  return {
    rootKey: rootKey,
    accountKey: accountKey,
    paymentKey: paymentKey,
    paymentKeyPub: paymentKeyPub,
    paymentKeyHash: paymentKeyHash,
    stakeKey: stakeKey,
    paymentAddr: paymentAddr,
    enterpriseAddr: enterpriseAddr,
  };
};