import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PostedItemContent, { FullscreenModal } from "./PostedItemContent";
import ShowItem from "./ShowItem";

import { Edit, Delete, Check, AddComment, Comment, Fullscreen } from "@mui/icons-material";
import { setEditingId } from "../redux/features/walletSlice";
import { useSetContentMutation, useDeleteContentMutation } from "../redux/apis/contentApi";
import { useSetCommentMutation } from "../redux/apis/commentApi";

import { devmode } from "../config";

import CustomEditor from "./CustomEditor";
import draftToHtml from 'draftjs-to-html';
import { Tooltip } from '@mui/material';

const PostedItem = ({ id, title, description, policy_id, type, url: initialUrl, edit_flag, showFullscreen = false }) => {

    const dispatch = useDispatch();
    const ref = useRef(null);

    const state_wallet = useSelector((state) => state.walletState);
    const state_comment = useSelector((state) => state.commentState);
    const state_user = useSelector((state) => state.userState);

    const [setContent] = useSetContentMutation();
    const [deleteContent] = useDeleteContentMutation();

    const [setComment] = useSetCommentMutation();

    const [eTitle, setETitle] = useState(title);
    const [eDescription, setEDescription] = useState(description);
    const [eUrl, setEUrl] = useState(initialUrl);
    const [eComment, setEComment] = useState("");

    const [showAskDelete, setShowAskDelete] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const [showComment, setShowComment] = useState(false);

    const [enableFlag, setEnableFlag] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [commentShowCount, setCommentShowCount] = useState(0);

    const [showContentEditor, setShowContentEditor] = useState(false);
    const [showTitleEditor, setShowTitleEditor] = useState(false);
    const [showDescriptionEditor, setShowDescriptionEditor] = useState(false);

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);

    const [parsedDescription, setParsedDescription] = useState('');

    const [isFullscreen, setIsFullscreen] = useState(false);

    const [isEditing, setIsEditing] = useState(false);

    const [url, setUrl] = useState(initialUrl);

    useEffect(() => {
        if (devmode) console.log("PostedItem->", id, title, description, policy_id, type, initialUrl, edit_flag);
        isEnableNFT();
    }, [state_wallet]);

    const onEdit = () => {
        if (devmode) console.log("onEdit", id);
        if (state_wallet.editingId != 0) {
            return;
        }
        dispatch(setEditingId(id));
    }

    const onDelete = () => {
        if (devmode) console.log("onDelete", id);
        if (state_wallet.editingId != 0) {
            return;
        }
        setShowAskDelete(true);
    }

    const onEditFihished = () => {
        if (devmode) console.log("onEditFihished", id);
        dispatch(setEditingId(0));

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const closeAskDelete = (e) => {
        setShowAskDelete(false);
    }

    const deleteCurrentContent = () => {
        const formdata = new FormData();
        formdata.append("id", id);
        deleteContent(formdata);
    }

    const closePlayer = (e) => {
        setShowPlayer(false);
    }

    const addComment = (e) => {
        setShowPlayer(false);
        setShowComment(true);
    }

    const closeComment = (e) => {
        setShowComment(false);
    }

    const okComment = () => {
        setShowComment(false);

        const formdata = new FormData();
        formdata.append("content_id", id);
        formdata.append("description", eComment);
        formdata.append("created_by", state_wallet.address);

        setComment(formdata);
    }

    const onChangeComment = (new_comment) => {
        setEComment(new_comment);
    }

    const onChangeContent = async (newUrl) => {
        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", title);
        formdata.append("description", description);
        formdata.append("content_type", type);
        formdata.append("url", newUrl);

        try {
            await setContent(formdata).unwrap();
            setEUrl(newUrl); // Update edit state
            setUrl(newUrl);  // Update display state
        } catch (error) {
            console.error('Failed to save content:', error);
            toast.error('Failed to save changes');
        }
    };

    const closeContentEditor = (e) => {
        setShowContentEditor(false);
    }

    const okContent = () => {
        setShowContentEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const onChangeTitle = (new_title) => {
        setETitle(new_title);
    }

    const closeTitleEditor = (e) => {
        setShowTitleEditor(false);
    }

    const okTitle = () => {
        setShowTitleEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const onChangeDescription = (new_description) => {
        setEDescription(new_description);
    }

    const closeDescriptionEditor = (e) => {
        setShowDescriptionEditor(false);
    }

    const okDescription = () => {
        setShowDescriptionEditor(false);

        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", eTitle);
        formdata.append("description", eDescription);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);
        setContent(formdata);
    }

    const isEnableNFT = () => {
        // Dev wallet check
        if (state_wallet.address === "addr1qx5fx5wg0scahca67s3eh8c5suyqaa0jppecrztqd80ru6f7fr59rddrj47q0c206dcgzqhcakr5t3crw04y5n8522zq8caymk") {
            setEnableFlag(true);
            return;
        }

        const walletPolicyIds = state_wallet.policy_ids || [];
        const userContentPolicy = state_user?.user?.PolicyId;
        
        // Use Set for O(1) lookups
        const policySet = new Set(walletPolicyIds);

        const hasGem = policySet.has(process.env.REACT_APP_GEM_POLICY_ID);
        const hasPowerGem = policySet.has(process.env.REACT_APP_POWER_GEM_POLICY_ID);
        const hasContentPolicy = policy_id && policySet.has(policy_id);
        const hasUserContentPolicy = userContentPolicy && policySet.has(userContentPolicy);

        setEnableFlag(hasGem || hasPowerGem || hasContentPolicy || hasUserContentPolicy);
    }

    const onContentUploading = (isContentUploading) => {
        setIsUploading(isContentUploading);
    }

    const onReadMore = () => {
        let count = commentShowCount + 5;
        setCommentShowCount(count);
    }

    const onLess = () => {
        let count = commentShowCount - 5;
        setCommentShowCount(count);
    }

    const openContentEditor = () => {
        setShowContentEditor(true);
    }

    // Individual section edit handlers
    const handleTitleEdit = () => {
        setShowTitleEditor(true);
    };

    const handleContentEdit = () => {
        setShowContentEditor(true);
    };

    const handleDescriptionEdit = () => {
        setShowDescriptionEditor(true);
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this content?')) {
            deleteCurrentContent();
        }
    };

    const handleSaveField = async (field) => {
        const formdata = new FormData();
        formdata.append("id", id);
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", field === 'title' ? eTitle : title);
        formdata.append("description", field === 'description' ? eDescription : description);
        formdata.append("content_type", type);
        formdata.append("url", eUrl);

        try {
            await setContent(formdata).unwrap();
            if (field === 'title') setIsEditingTitle(false);
            if (field === 'description') setIsEditingDescription(false);
        } catch (error) {
            console.error('Failed to save:', error);
            toast.error('Failed to save changes');
        }
    };

    const renderFullscreenButton = () => {
        // Only show fullscreen button if content is accessible (editing mode or user has NFT access)
        if (!edit_flag && !enableFlag) return null;

        return (
            <button
                onClick={() => setIsFullscreen(true)}
                className="absolute top-2 right-2 z-10 p-2 bg-black/50 hover:bg-black/70 rounded-full text-white/80 hover:text-white transition-all transform hover:scale-105"
                data-tooltip-id="fullscreen-tooltip"
                data-tooltip-content="View in fullscreen"
            >
                <Fullscreen sx={{ fontSize: "20px" }} />
            </button>
        );
    };

    return (
        <div className="relative w-full flex flex-col gap-6 bg-[#1a1a1a]/50 backdrop-blur-sm p-6 rounded-xl border border-white/10 hover:border-white/20 transition-all duration-300 group">
            {/* Title Section */}
            <div className="relative w-full rounded-lg p-4 bg-[#2a2a2a]/50 border border-white/5">
                {isEditingTitle ? (
                    <div className="space-y-4">
                        <textarea
                            value={eTitle}
                            onChange={(e) => setETitle(e.target.value)}
                            className="w-full bg-[#1a1a1a] border border-white/10 rounded-lg p-3 text-white resize-none focus:outline-none focus:border-blue-500"
                            rows={2}
                        />
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setIsEditingTitle(false)}
                                className="px-4 py-2 text-white/60 hover:text-white transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => handleSaveField('title')}
                                className="flex items-center gap-2 px-4 py-2 bg-green-600/20 border border-green-500 rounded-lg text-white hover:bg-green-600/30 transition-colors"
                            >
                                <Check sx={{ fontSize: "20px" }} />
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-between items-start gap-4">
                        <p className="w-full overflow-hidden text-base sm:text-lg text-white/90" 
                           dangerouslySetInnerHTML={{ __html: title }} 
                        />
                        {edit_flag && (
                            <button 
                                onClick={() => setIsEditingTitle(true)}
                                className="shrink-0 flex items-center gap-1 px-2 py-1 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors text-xs"
                            >
                                <Edit sx={{ fontSize: "16px" }} />
                                <span>Edit</span>
                            </button>
                        )}
                    </div>
                )}
            </div>

            {/* Content Section - Adjusted height and spacing */}
            <div className="w-full h-[400px] bg-[#2a2a2a]/50 rounded-lg border border-white/5 overflow-hidden">
                <PostedItemContent 
                    id={id}
                    type={type}
                    url={eUrl}
                    policy_id={policy_id}
                    edit_flag={edit_flag}
                    onDelete={handleDelete}
                    onChange={onChangeContent}
                    onContentUploading={setIsUploading}
                    isEditing={isEditing}
                    onEditStart={() => setIsEditing(true)}
                    onEditEnd={() => setIsEditing(false)}
                    showFullscreenOption={showFullscreen}
                />
            </div>

            {/* Description Section */}
            <div className="relative w-full rounded-lg p-4 bg-[#2a2a2a]/50 border border-white/5">
                {isEditingDescription ? (
                    <div className="space-y-4">
                        <CustomEditor
                            type="text"
                            url={eDescription}
                            onChange={(value) => setEDescription(value)}
                            onContentUploading={() => {}}
                            darkMode={true}
                        />
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setIsEditingDescription(false)}
                                className="px-4 py-2 text-white/60 hover:text-white transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => handleSaveField('description')}
                                className="flex items-center gap-2 px-4 py-2 bg-green-600/20 border border-green-500 rounded-lg text-white hover:bg-green-600/30 transition-colors"
                            >
                                <Check sx={{ fontSize: "20px" }} />
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-between items-start gap-4">
                        <div 
                            className="w-full overflow-hidden text-white/90 prose prose-invert max-w-none"
                            dangerouslySetInnerHTML={{ __html: description }} 
                        />
                        {edit_flag && (
                            <button 
                                onClick={() => setIsEditingDescription(true)}
                                className="shrink-0 flex items-center gap-1 px-2 py-1 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors text-xs"
                            >
                                <Edit sx={{ fontSize: "16px" }} />
                                <span>Edit</span>
                            </button>
                        )}
                    </div>
                )}
            </div>

            <FullscreenModal
                isOpen={isFullscreen}
                onClose={() => setIsFullscreen(false)}
                type={type}
                url={eUrl}
                title={title}
            />
            <Tooltip id="fullscreen-tooltip" />
        </div>
    );
};

export default PostedItem;