import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { setUser, setExploreUser, setPopularUsers, setFeaturedUsers } from '../features/userSlice'

const baseUrl = `${process.env.REACT_APP_STREAMKEY_API_URL}`

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        setUser: builder.mutation({
            query(data) {
                return {
                    url: 'user/',
                    method: 'POST',
                    body: data
                }
            },
            transformResponse: (result) =>
                result.user,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data));
                } catch (error) { console.log(error); }
            },
        }),
        getUserByCode: builder.query({
            query(code) {
                return {
                    url: `user/code/${code}`,
                }
            },
            transformResponse: (result) =>
                result.user,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setExploreUser(data));
                } catch (error) { console.log(error); }
            },
        }),
        getPopularUsers: builder.query({
            query() {
                return {
                    url: `user/popular/`,
                }
            },
            transformResponse: (result) =>
                result.users,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setPopularUsers(data));
                } catch (error) { console.log(error); }
            },
        }),
        getFeaturedUsers: builder.query({
            query() {
                return {
                    url: `user/featured/`,
                }
            },
            transformResponse: (result) =>
                result.users,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setFeaturedUsers(data));
                } catch (error) { console.log(error); }
            },
        }),
        checkCodeAvailability: builder.query({
            query(code) {
                return {
                    url: `user/check-code/${code}`,
                }
            },
            transformResponse: (response) => response.available,
        }),
    }),
});

export const {
    useSetUserMutation,
    useGetUserByCodeQuery,
    useGetPopularUsersQuery,
    useGetFeaturedUsersQuery,
    useCheckCodeAvailabilityQuery
} = userApi;

export const checkCodeInDatabase = async (code) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_STREAMKEY_API_URL}/user/check-code/${code}`);
        if (!response.ok) {
            throw new Error('Failed to check code availability');
        }
        const data = await response.json();
        return {
            exists: data.exists,
            error: null
        };
    } catch (error) {
        console.error('Code check error:', error);
        throw new Error("Failed to check code availability");
    }
};