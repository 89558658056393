import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { setContent, setContents, deleteContent, setExploreContents } from '../features/contentSlice'

const baseUrl = `${process.env.REACT_APP_STREAMKEY_API_URL}`

export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['Content'],
    endpoints: (builder) => ({
        setContent: builder.mutation({
            query(data) {
                return {
                    url: 'content/',
                    method: 'POST',
                    body: data
                }
            },
            transformResponse: (result) =>
                result.content,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setContent(data));
                } catch (error) { console.log(error); }
            },
        }),
        getContentsByAddress: builder.query({
            query(address) {
                return {
                    url: `content/address/${address}`,
                }
            },
            transformResponse: (result) =>
                result.contents,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setContents(data));
                } catch (error) { console.log(error); }
            },
        }),
        getContentsByCode: builder.query({
            query(code) {
                return {
                    url: `content/code/${code}`,
                }
            },
            transformResponse: (result) =>
                result.contents,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setExploreContents(data));
                } catch (error) { console.log(error); }
            },
        }),
        deleteContent: builder.mutation({
            query(data) {
                return {
                    url: 'content/delete/',
                    method: 'POST',
                    body: data
                }
            },
            transformResponse: (result) =>
                result.content,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(deleteContent(data));
                } catch (error) { console.log(error); }
            },
        }),
    }),
});

export const {
    useSetContentMutation,
    useGetContentsByAddressQuery,
    useDeleteContentMutation,
    useGetContentsByCodeQuery
} = contentApi;