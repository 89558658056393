import { createSlice } from '@reduxjs/toolkit';

import { devmode } from "../../config";

const initialState = {
  comments: [],
  explore_comments: [],
}

const commentSlice = createSlice({
  name: 'commentSlice',
  initialState,
  reducers: {
    setComments: (state, action) => {
      if (devmode) console.log("setComments", action.payload)
      state.comments = action.payload;
    },
    setComment: (state, action) => {
      if (devmode) console.log("setComment", action.payload)
      let tmp_comments = [...state.comments];
      let comment_index = -1;
      tmp_comments.map((comment, index) => {
        if (comment.ID == action.payload.ID) {
          comment_index = index;
        }
      })
      if (comment_index != -1) {
        tmp_comments[comment_index] = action.payload;
      } else {
        tmp_comments.push(action.payload);
      }
      state.comments = tmp_comments;

      tmp_comments = [...state.explore_comments];
      comment_index = -1;
      tmp_comments.map((comment, index) => {
        if (comment.ID == action.payload.ID) {
          comment_index = index;
        }
      })
      if (comment_index != -1) {
        tmp_comments[comment_index] = action.payload;
      } else {
        tmp_comments.push(action.payload);
      }
      state.explore_comments = tmp_comments;
    },
    deleteComment: (state, action) => {
      if (devmode) console.log("deleteComment", action.payload)
      let tmp_comments = [...state.comments];
      let comment_index = -1;
      tmp_comments.map((comment, index) => {
        if (comment.ID == action.payload.ID) {
          comment_index = index;
        }
      })
      if (comment_index != -1) {
        tmp_comments.splice(comment_index, 1);
      }
      state.comments = tmp_comments;
    },
    setExploreComments: (state, action) => {
      if (devmode) console.log("setExploreComments", action.payload)
      state.explore_comments = action.payload;
    },
  },
})

export default commentSlice.reducer

export const { setComments, setComment, deleteComment, setExploreComments } = commentSlice.actions