
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePostItem from "../components/HomePostItem";

import { useGetPopularUsersQuery, useGetFeaturedUsersQuery } from "../redux/apis/userApi";

import { devmode } from "../config";

const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);

    useGetPopularUsersQuery();
    useGetFeaturedUsersQuery();

    useEffect(() => {
        if (devmode) console.log("state:", state_wallet)
    }, [state_wallet.connected]);

    return (
        <div className=" w-full h-auto flex flex-col gap-[10px] lg:gap-[60px] pb-[200px] lg:pb-[100px] bg-main_bg">
            <div className="relative px-[10px] lg:px-[200px] pt-[120px] lg:pt-[100px] w-full flex flex-col gap-[40px] items-center justify-center px-[10px] lg:px-[20px] py-[20px] z-0">
                <img src="/images/stk_banner_bg.png" alt="" className="absolute top-0 left-0 h-full object-cover" />
                <img src="/images/stk_logo.png" alt="" className="w-3/4 lg:w-1/2 object-contain z-0" />
                <div className="text-white font-bold text-[20px] lg:text-[30px] z-0">Welcome to StreamKey!</div>
                <div className="flex flex-col md:flex-row gap-[40px] z-0">
                    <div className="bg-white/10 backdrop-blur-sm px-[25px] py-[15px] border border-white rounded-full text-white font-bold text-[20px] hover:cursor-pointer hover:scale-110" onClick={(e) => { state_wallet.connected ? navigate("/create") : toast("Connect Wallet First!") }}>Create Your StreamKey</div>
                    {state_wallet.connected && state_user.user ?
                        <a href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`}> <div className="bg-white/10 backdrop-blur-sm px-[25px] py-[15px] border border-white rounded-full text-white font-bold text-[20px] hover:cursor-pointer hover:scale-110">Explore Your Assets</div></a> :
                        <div className="bg-white/10 backdrop-blur-sm px-[25px] py-[15px] border border-white rounded-full text-white font-bold text-[20px] hover:cursor-pointer hover:scale-110" onClick={(e) => { toast("Connect Wallet First!") }}>Explore Your Assets</div>
                    }
                </div>
            </div>
            <div className="px-[10px] lg:px-[200px] w-full py-[20px] px-[5px] flex flex-col gap-[100px]">
                <div className="text-[30px] lg:text-[40px] font-bold text-white text-center">Featured Creators</div>
                <div className="w-full px-[20px] flex flex-row gap-[20px] items-center">
                    <div className="relative w-full h-full rounded-xl overflow-hidden shadow-xl p-[40px] bg-white/10 backdrop-blur-sm">
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-[10px] gap-y-[10px]">
                            {state_user.popular_users.map((popular_user) => {
                                return (
                                    <HomePostItem asset={popular_user} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="px-[10px] lg:px-[200px] w-full py-[20px] px-[5px] flex flex-col gap-[100px]">
                <div className="text-[30px] lg:text-[40px] font-bold text-white text-center">Featured Contents</div>
                <div className="w-full px-[20px] flex flex-row gap-[20px] items-center">
                    <div className="relative w-full h-full rounded-xl overflow-hidden shadow-xl p-[40px] bg-white/10 backdrop-blur-sm">
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-y-[20px]">
                            {state_user.featured_users.map((featured_user) => {
                                return (
                                    <HomePostItem asset={featured_user} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    );
};

export default Home;