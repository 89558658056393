
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PostedItem from "../components/PostedItem";

import { useGetContentsByCodeQuery, useSetContentMutation } from "../redux/apis/contentApi";
import { useGetUserByCodeQuery } from "../redux/apis/userApi";
import { useGetCommentsByCodeQuery } from "../redux/apis/commentApi";

import { ShareSocial } from 'react-share-social';

import { devmode } from "../config";

const share_social_style = {
    root: {
        background: 'transparent',
        padding: "0px",
        display: "flex",
        justifyContent: "center",
    },
    copyContainer: {
        display: 'none',
    },
};

const Explore = () => {

    const { code } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);
    const state_content = useSelector((state) => state.contentState);

    useGetContentsByCodeQuery(code);
    useGetUserByCodeQuery(code);
    useGetCommentsByCodeQuery(code);

    useEffect(() => {
        if (devmode) console.log("state_wallet, code", state_wallet, code)
    }, [state_wallet.connected])

    return (
        <>
            <div className="w-full h-auto flex flex-col gap-[10px] lg:gap-[60px] bg-main_bg">
                <div className="relative w-full min-h-[100vh] flex flex-col gap-[40px] items-center px-[10px] z-0 pt-[120px] lg:pt-[100px] pb-[200px] lg:pb-[100px]">
                    <div className="text-white font-bold text-[30px] lg:text-[50px] text-center">Welcome to <span dangerouslySetInnerHTML={{ __html: state_user.explore_user ? state_user.explore_user.Title.replace("<p>", "<span>").replace("</p>", "</span>") : "" }} /> StreamKey!</div>
                    {state_user.explore_user ?
                        <div className="text-white font-bold text-[15px] lg:text-[20px] text-center">You must have a <a className="underline" href={`${state_user.explore_user.BuyLink ? state_user.explore_user.BuyLink : "https://nfthor.io/collection/Gems"}`}>{state_user.explore_user.NftName ? state_user.explore_user.NftName : "Gems"}</a> NFT in your wallet to access content.</div> :
                        <></>
                    }
                    {/* {state_user.explore_user ?
                        <div className="text-white font-bold text-[15px] lg:text-[20px] text-center">Policy ID: {state_user.explore_user.PolicyId.slice(0, state_user.explore_user.PolicyId.length / 2)} {state_user.explore_user.PolicyId.slice(state_user.explore_user.PolicyId.length / 2)}</div> :
                        <></>
                    } */}
                    {/* <div className="text-white font-bold text-[15px] lg:text-[20px] text-center">You can buy it here <a className="underline" href="https://NFTHOR.io">NFTHOR.io</a></div> */}
                    {state_user.user && state_user.user.Code == code ?
                        <div className="w-full pr-[20px] text-white font-bold text-[15px] text-center md:text-end">{process.env.REACT_APP_STREAMKEY_URL}/StreamKey/{state_user.user.Code}</div> :
                        <></>
                    }
                    <div className="relative w-full h-[200px] md:h-[300px] flex">
                        <img src={state_user.explore_user && state_user.explore_user.Banner ? state_user.explore_user.Banner : "/images/stk_banner_bg.png"} alt="" className="w-full h-full object-cover" />
                        <div className="absolute -bottom-[50px] left-0 w-full h-[100px] flex flex-row justify-center">
                            <img src={state_user.explore_user && state_user.explore_user.Badge ? state_user.explore_user.Badge : "/images/stk_icon.png"} alt="" className="w-[100px] h-[100px] object-cover rounded-full" />
                        </div>
                    </div>
                    <ShareSocial
                        url={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user ? state_user.user.Code : ""}`}
                        socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
                        style={share_social_style}
                    />
                    <div className="w-5/6 md:w-1/2 flex flex-col gap-[30px] !-mt-[40px]">
                        {state_user.explore_user && state_user.explore_user.Title ?
                            <div className="relative flex flex-row justify-center">
                                <p className="w-full h-[60px] text-white text-[40px] text-center bg-transparent px-[10px] py-[5px] overflow-hidden" dangerouslySetInnerHTML={{ __html: state_user.explore_user.Title }} />
                            </div> : <></>
                        }
                        {state_user.explore_user && state_user.explore_user.CreatorName ?
                            <div className="relative flex flex-row justify-center">
                                <p className="w-full h-[50px] text-white text-[30px] text-center bg-transparent px-[10px] py-[5px] overflow-hidden" dangerouslySetInnerHTML={{ __html: state_user.explore_user.CreatorName }} />
                            </div> : <></>
                        }
                        <div className="relative flex flex-row">
                            <p className="w-full h-[150px] text-white text-[15px] bg-white/10 backdrop-blur-sm p-[5px] rounded-lg px-[10px] py-[5px] overflow-hidden" dangerouslySetInnerHTML={{ __html: state_user.explore_user ? state_user.explore_user.Description : "" }} />
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            {state_content.explore_contents.length != 0 ?
                                <div className="text-white font-bold text-[20px] text-center">Posted Contents</div> :
                                <></>
                            }
                            {state_content.explore_contents.length != 0 && state_content.explore_contents.map((content) => {
                                return (
                                    <PostedItem id={content.ID} title={content.Title} description={content.Description} policy_id={state_user.explore_user ? state_user.explore_user.PolicyId : ""} type={content.ContentType} url={content.Url} edit_flag={false} />
                                );
                            })
                            }
                            {state_content.explore_contents.length == 0 ?
                                <div className="text-white font-bold text-[20px] lg:text-[30px] text-center">You do not have any assets yet. Please go to Create page and create an asset.</div> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Explore;