import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Edit, Delete, AddComment, Check, Fullscreen, Close, CloudUpload } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import CustomEditor from "./CustomEditor";
import { devmode } from "../config";
import { Lock } from "@mui/icons-material";
import PdfViewer from "./PdfViewer";
import { uploadToLocalStorage } from "../cardano/market-api/helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from 'react-tooltip';

export const FullscreenModal = ({ isOpen, onClose, type, url, title }) => {
    if (!isOpen) return null;

    const getFullscreenContent = () => {
        switch (type) {
            case "image":
                return (
                    <img src={url} alt={title} className="max-h-[90vh] max-w-[90vw] object-contain" />
                );
            case "video":
                return (
                    <div className="relative w-[90vw] h-[90vh] flex items-center justify-center bg-black rounded-lg overflow-hidden">
                        <ReactPlayer 
                            url={url} 
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0 }}
                            controls
                            playing
                            config={{
                                file: {
                                    attributes: {
                                        style: {
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                );
            case "audio":
                return (
                    <div className="w-full max-w-2xl bg-[#2a2a2a] rounded-lg p-8">
                        <div className="flex items-center gap-4">
                            <i className="fas fa-music text-5xl text-white/60" />
                            <audio 
                                controls 
                                className="w-full" 
                                autoPlay
                                controlsList="nodownload"
                            >
                                <source src={url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </div>
                );
            case "pdf":
                return (
                    <div className="w-[90vw] h-[90vh] bg-white rounded-lg overflow-hidden">
                        <PdfViewer url={url} isThumbnail={false} isFullscreen={true} />
                    </div>
                );
            case "text":
                return (
                    <div className="w-[90vw] h-[90vh] bg-[#1a1a1a] rounded-lg overflow-hidden">
                        <div className="w-full h-full overflow-y-auto p-8 text-white/90 prose prose-invert max-w-none">
                            <div 
                                className="text-lg leading-relaxed"
                                dangerouslySetInnerHTML={{ __html: url }} 
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 flex items-center justify-center">
            <div className="relative w-full h-full flex flex-col items-center justify-center p-4">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-white/80 hover:text-white transition-colors"
                >
                    <Close sx={{ fontSize: "32px" }} />
                </button>
                <h2 className="text-white/90 text-xl font-semibold mb-4">{title}</h2>
                {getFullscreenContent()}
            </div>
        </div>
    );
};

const PostedItemContent = ({ 
    id, 
    type, 
    url: initialUrl, 
    policy_id, 
    edit_flag, 
    onDelete, 
    onChange, 
    onContentUploading: onContentUploadingProp,
    isEditing,
    onEditStart,
    onEditEnd
}) => {

    const dispatch = useDispatch();

    const ref = useRef(null);

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);

    const [enableFlag, setEnableFlag] = useState(false);
    // const [isUploading, setIsUploading] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false);
    const [comment, setComment] = useState("");

    const fileInputRef = useRef(null);

    const [editorContent, setEditorContent] = useState(initialUrl || '');
    // const [isEditing, setIsEditing] = useState(false);

    const [showFullscreenView, setShowFullscreenView] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const [previewUrl, setPreviewUrl] = useState(null);

    const [url, setUrl] = useState(initialUrl);

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [contentTitle, setContentTitle] = useState('Expanded View');

    useEffect(() => {
        if (devmode) console.log("PostedItemContent->", id, type, url, policy_id, edit_flag);
        isEnableNFT();
    }, [state_wallet]);

    const getItemContent = () => {
        switch (type) {
            case "image":
                return (
                    <img src={url} alt="" className="h-full w-full object-contain" />
                );
            case "text":
                return (
                    <div className="w-full h-full overflow-y-auto p-4 text-white/90 prose prose-invert max-w-none">
                        <div 
                            className="text-base leading-relaxed"
                            dangerouslySetInnerHTML={{ __html: url }} 
                        />
                    </div>
                );
            case "audio":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center">
                        <div className="w-full max-w-md bg-[#2a2a2a] rounded-lg p-4">
                            <div className="flex items-center gap-4 mb-4">
                                <i className="fas fa-music text-3xl text-white/60" />
                                <div className="flex-1">
                                    <audio 
                                        controls 
                                        className="w-full" 
                                        controlsList="nodownload"
                                    >
                                        <source src={url} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "video":
                return (
                    <div className="relative w-full h-full flex items-center justify-center">
                        <ReactPlayer 
                            url={url} 
                            width="100%" 
                            height="100%" 
                            style={{ position: 'absolute', top: 0, left: 0 }}
                            muted 
                            loop 
                            playing 
                            config={{
                                file: {
                                    attributes: {
                                        style: {
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                );
            case "pdf":
                return (
                    <PdfViewer url={url} isThumbnail={true} />
                );
        }
    }

    // const getItemAcceptType = () => {
    //     switch (type) {
    //         case "image":
    //             return "image/png, image/jpg, image/jpeg, image/webp";
    //         case "text":
    //             return "";
    //         case "audio":
    //             return "audio/mp3, audio/wav, audio/ogg";
    //         case "video":
    //             return "video/webm, video/mp4, video/wmv, video/avi, video/mpg";
    //     }
    // }

    // const onFileChange = async (e) => {
    //     if (devmode) console.log("onFileChange", e);

    //     if (e.target.files.length != 0) {
    //         setIsUploading(true);
    //         onContentUploading(true);
    //         const res = await uploadToNFTStorage(e.target.files[0]);
    //         setIsUploading(false);
    //         onContentUploading(false);
    //         if (devmode) console.log(res);
    //         if (res.success) {
    //             if (devmode) console.log(res.data.value.cid);
    //             const filepath = `${process.env.REACT_APP_IPFS_URL}/${res.data.value.cid}/${res.data.value.files[0].name}`;
    //             setContentUrl(filepath);
    //             onContentChanged(filepath);
    //         }
    //     }
    // }

    const isEnableNFT = () => {
        if (state_wallet.address === "addr1q9mw3rnd04gkyr05je3zsp0lxwt6r7usrn5rj8078cwlxjf2573u5jt7v8g39wwtgfdkp3fq9da4emmn2d4y9tnsfaxslcdmap") {
            setEnableFlag(true);
            return;
        }

        const walletPolicyIds = state_wallet.policy_ids || [];
        
        // Check for gems first
        const hasGem = walletPolicyIds.some(id => id === process.env.REACT_APP_GEM_POLICY_ID);
        const hasPowerGem = walletPolicyIds.some(id => id === process.env.REACT_APP_POWER_GEM_POLICY_ID);
        
        // Check content policy if provided
        const hasContentPolicy = policy_id && walletPolicyIds.some(id => id === policy_id);

        console.log('Content Access Check:', {
            walletAddress: state_wallet.address,
            walletPolicyIds,
            gemPolicyId: process.env.REACT_APP_GEM_POLICY_ID,
            powerGemPolicyId: process.env.REACT_APP_POWER_GEM_POLICY_ID,
            contentPolicyId: policy_id,
            hasGem,
            hasPowerGem,
            hasContentPolicy
        });

        setEnableFlag(hasGem || hasPowerGem || hasContentPolicy);
    }

    const handleContentClick = () => {
        if (!edit_flag) {
            setShowFullscreenView(true);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleAddComment = () => {
        // TODO: Implement comment submission
        setShowCommentForm(false);
        setComment("");
    };

    const getPlaceholderContent = () => {
        switch (type) {
            case "image":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-image text-4xl mb-2" />
                        <p>Click Edit to upload an image</p>
                        <p className="text-sm">Supported: PNG, JPG, GIF</p>
                    </div>
                );
            case "video":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-video text-4xl mb-2" />
                        <p>Click Edit upload a video</p>
                        <p className="text-sm">Supported: MP4, AVI, WMV</p>
                    </div>
                );
            case "audio":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-music text-4xl mb-2" />
                        <p>Click Edit upload audio</p>
                        <p className="text-sm">Supported: MP3, WAV, OGG</p>
                    </div>
                );
            case "text":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-pen-to-square text-4xl mb-2" />
                        <p>Click Edit add text</p>
                        <p className="text-sm">Rich text editor with formatting</p>
                    </div>
                );
            case "pdf":
                return (
                    <div className="h-full w-full flex flex-col items-center justify-center text-white/60">
                        <i className="fas fa-file-pdf text-4xl mb-2" />
                        <p>Click Edit upload a PDF</p>
                        <p className="text-sm">Document viewer included</p>
                    </div>
                );
            default:
                return null;
        }
    };

    const onChangeContent = (newContent) => {
        // Pass the content change up to the parent component
        if (onChange) {
            onChange(newContent);
        }
    };

    const onContentUploading = (isUploading) => {
        // Pass the upload state up to the parent component
        if (onContentUploadingProp) {
            onContentUploadingProp(isUploading);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Create preview URL for the file
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);

        try {
            onContentUploadingProp(true);
            const result = await uploadToLocalStorage(file, state_wallet.address);
            
            if (result.success) {
                // Construct the correct URL path
                const filepath = `${process.env.REACT_APP_STREAMKEY_API_URL}/api/uploads/${result.data.path}`;
                if (typeof onChange === 'function') {
                    onChange(filepath);
                    setUrl(filepath); // Update local URL state
                }
            } else {
                toast.error('Upload failed. Please try again.');
                setPreviewUrl(null);
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast.error('Upload failed. Please try again.');
            setPreviewUrl(null);
        } finally {
            onContentUploadingProp(false);
        }
    };

    const getAcceptedFileTypes = () => {
        switch (type) {
            case "image":
                return "image/png, image/jpg, image/jpeg, image/webp";
            case "audio":
                return "audio/mp3, audio/wav, audio/ogg";
            case "video":
                return "video/webm, video/mp4, video/wmv, video/avi, video/mpg";
            case "pdf":
                return "application/pdf";
            default:
                return "";
        }
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
        setPreviewUrl(content);
    };

    const handleEdit = () => {
        onEditStart();
    };

    const handleCancel = () => {
        onEditEnd();
    };

    const handleSave = async () => {
        if (type === "text") {
            onChange(editorContent);
            setUrl(editorContent);
        } else {
            onChange(previewUrl);
            setUrl(previewUrl);
        }
        onEditEnd();
    };

    const getUploadInstructions = (type) => {
        switch (type) {
            case "image":
                return "Supported formats: PNG, JPG, JPEG, WebP";
            case "audio":
                return "Supported formats: MP3, WAV, OGG";
            case "video":
                return "Supported formats: MP4, WebM, AVI";
            case "pdf":
                return "Upload PDF documents";
            default:
                return "";
        }
    };

    // Clean up preview URL when component unmounts
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    const getPreviewContent = () => {
        if (!previewUrl) return null;

        switch (type) {
            case "image":
                return (
                    <img src={previewUrl} alt="Preview" className="max-h-48 object-contain mx-auto" />
                );
            case "video":
                return (
                    <ReactPlayer 
                        url={previewUrl} 
                        width="100%" 
                        height="200px" 
                        controls
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload'
                                }
                            }
                        }}
                    />
                );
            case "audio":
                return (
                    <div className="w-full max-w-md mx-auto">
                        <audio 
                            controls 
                            className="w-full" 
                            controlsList="nodownload"
                        >
                            <source src={previewUrl} />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                );
            case "pdf":
                return (
                    <div className="max-h-48 overflow-hidden">
                        <PdfViewer url={previewUrl} />
                    </div>
                );
            default:
                return null;
        }
    };

    const getContentTypeInfo = () => {
        switch (type) {
            case "image":
                return {
                    icon: "🖼️",
                    label: "Image Content",
                    tooltip: "This image is protected content requiring NFT ownership to view"
                };
            case "text":
                return {
                    icon: "📝",
                    label: "Text Content",
                    tooltip: "This article/text is protected content requiring NFT ownership to view"
                };
            case "audio":
                return {
                    icon: "🎵",
                    label: "Audio Content",
                    tooltip: "This audio track is protected content requiring NFT ownership to view"
                };
            case "video":
                return {
                    icon: "🎥",
                    label: "Video Content",
                    tooltip: "This video is protected content requiring NFT ownership to view"
                };
            case "pdf":
                return {
                    icon: "📄",
                    label: "PDF Document",
                    tooltip: "This document is protected content requiring NFT ownership to view"
                };
            default:
                return {
                    icon: "📁",
                    label: "Content",
                    tooltip: "Protected content requiring NFT ownership to view"
                };
        }
    };

    const getContentDisplay = () => {
        // Always show content if editing or if user has NFT access
        if (edit_flag || enableFlag) {
            if (url) {
                return getItemContent();
            } else if (isEditing && previewUrl) {
                return getPreviewContent();
            } else if (previewUrl) {
                return getPreviewContent();
            }
            return getPlaceholderContent();
        }
        
        // Show blurred preview if locked
        return (
            <div className="relative w-full h-full">
                <div className="absolute inset-0 filter blur-xl">
                    {getItemContent()}
                </div>
                <div className="absolute inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center">
                    <div className="max-w-sm mx-auto p-6 text-center">
                        <Lock className="text-white text-3xl mb-3" />
                        <h3 className="text-white text-lg font-medium mb-2">NFT Required</h3>
                        <p className="text-white/80 text-sm mb-4">
                            Hold the required NFT to unlock this exclusive content
                        </p>
                        <a 
                            href={state_user.explore_user?.BuyLink || "https://nfthor.io/collection/Gems"}
                            className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>Unlock Content</span>
                            <i className="fas fa-external-link-alt text-sm" />
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const getEditingInterface = () => {
        return (
            <div className="w-full h-full p-6">
                <div className="flex flex-col h-full gap-6">
                    {/* Content Area */}
                    <div className="flex-1 min-h-0">
                        {type === "text" ? (
                            <CustomEditor 
                                type="text"
                                url={editorContent}
                                onChange={handleEditorChange}
                                onContentUploading={onContentUploadingProp}
                                darkMode={true}
                            />
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 h-full">
                                {/* Preview or Current Content */}
                                <div className="bg-black/30 rounded-lg overflow-hidden">
                                    <div className="h-full flex items-center justify-center relative">
                                        {previewUrl ? (
                                            <div className="relative h-full w-full">
                                                {getPreviewContent()}
                                                <div className="absolute top-2 left-2 px-3 py-1.5 bg-green-500/20 border border-green-500 rounded-full">
                                                    <p className="text-green-500 text-xs font-medium">New {type}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            url && getItemContent()
                                        )}
                                    </div>
                                </div>

                                {/* Upload Area */}
                                <div className="flex flex-col gap-4">
                                    <div 
                                        onClick={() => fileInputRef.current?.click()}
                                        className="h-full border-2 border-dashed border-white/20 rounded-lg p-8 hover:border-white/40 transition-colors cursor-pointer flex flex-col items-center justify-center gap-4"
                                    >
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            className="hidden"
                                            accept={getAcceptedFileTypes()}
                                            onChange={handleFileUpload}
                                        />
                                        <div className="p-4 rounded-full bg-blue-500/10 border border-blue-500">
                                            <CloudUpload sx={{ fontSize: "32px", color: "#3b82f6" }} />
                                        </div>
                                        <div className="text-center">
                                            <p className="text-white font-medium mb-1">Click to upload or drag and drop</p>
                                            <p className="text-white/60 text-sm">{getUploadInstructions(type)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center justify-between border-t border-white/10 pt-4">
                        <div className="text-white/60 text-sm">
                            {previewUrl ? 
                                <span className="text-green-500">✓ New content ready to save</span> : 
                                type === "text" ? 
                                    "Edit text content in the editor above" :
                                    "Upload new content to make changes"
                            }
                        </div>
                        <div className="flex gap-2">
                            <button
                                onClick={handleCancel}
                                className="px-4 py-2 text-white/60 hover:text-white transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                disabled={!previewUrl && type !== "text"}
                                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                                    ${(!previewUrl && type !== "text") 
                                        ? 'bg-green-600/10 border border-green-500/30 text-white/30 cursor-not-allowed' 
                                        : 'bg-green-600/20 border border-green-500 text-white hover:bg-green-600/30'}`}
                            >
                                <Check sx={{ fontSize: "20px" }} />
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="relative h-full">
            {/* Content Type Header with Fullscreen */}
            <div className="absolute top-2 left-2 z-10 flex items-center gap-2">
                <div className="flex items-center gap-2 bg-black/50 backdrop-blur-sm px-3 py-1.5 rounded-lg">
                    <span className="text-xl">{getContentTypeInfo().icon}</span>
                    <span className="text-white/80 text-sm">{getContentTypeInfo().label}</span>
                </div>
                
                {/* Fullscreen Button - Only show when content is accessible */}
                {enableFlag && !isEditing && (
                    <button
                        onClick={() => setIsFullscreen(true)}
                        className="p-2 bg-black/50 hover:bg-black/70 rounded-lg text-white/80 hover:text-white transition-all transform hover:scale-105"
                        data-tooltip-id="fullscreen-tooltip"
                        data-tooltip-content="View in fullscreen"
                    >
                        <Fullscreen sx={{ fontSize: "20px" }} />
                    </button>
                )}
            </div>

            {/* Rest of the component remains the same */}
            <div className={`h-full transition-opacity duration-300 ${isEditing ? 'opacity-0' : 'opacity-100'}`}>
                {getContentDisplay()}
            </div>

            {/* Edit Interface */}
            <div className={`absolute inset-0 bg-[#1a1a1a] transition-opacity duration-300 ${isEditing ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                {getEditingInterface()}
            </div>

            {/* Edit/Delete Controls - Moved to avoid overlap */}
            {edit_flag && !isEditing && (
                <div className="absolute top-2 right-2 z-20 flex gap-2">
                    <button 
                        onClick={onEditStart}
                        className="flex items-center gap-1 px-3 py-1.5 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors text-sm"
                    >
                        <Edit sx={{ fontSize: "16px" }} />
                        <span>Edit</span>
                    </button>
                    <button
                        onClick={onDelete}
                        className="flex items-center gap-1 px-3 py-1.5 bg-red-600/20 border border-red-500 rounded-lg text-white hover:bg-red-600/30 transition-colors text-sm"
                    >
                        <Delete sx={{ fontSize: "16px" }} />
                        <span>Delete</span>
                    </button>
                </div>
            )}

            <FullscreenModal
                isOpen={isFullscreen}
                onClose={() => setIsFullscreen(false)}
                type={type}
                url={url}
                title={contentTitle}
            />
            <Tooltip id="fullscreen-tooltip" />
        </div>
    );
};

export default PostedItemContent;