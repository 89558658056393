import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';

import { walletApi } from './apis/walletApi';
import { userApi } from './apis/userApi';
import { contentApi } from './apis/contentApi';
import { commentApi } from './apis/commentApi';

import walletReducer from './features/walletSlice';
import userReducer from './features/userSlice';
import contentReducer from './features/contentSlice';
import commentReducer from './features/commentSlice';

export const store = configureStore({
  reducer: {
    [walletApi.reducerPath]: walletApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,

    walletState: walletReducer,
    userState: userReducer,
    contentState: contentReducer,
    commentState: commentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      walletApi.middleware,
      userApi.middleware,
      contentApi.middleware,
      commentApi.middleware,
    ]),
})

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;