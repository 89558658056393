import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { setComment, setComments, deleteComment, setExploreComments } from '../features/commentSlice'

const baseUrl = `${process.env.REACT_APP_STREAMKEY_API_URL}`

export const commentApi = createApi({
    reducerPath: 'commentApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['Comment'],
    endpoints: (builder) => ({
        setComment: builder.mutation({
            query(data) {
                return {
                    url: 'comment/',
                    method: 'POST',
                    body: data
                }
            },
            transformResponse: (result) =>
                result.comment,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setComment(data));
                } catch (error) { console.log(error); }
            },
        }),
        getCommentsByAddress: builder.query({
            query(address) {
                return {
                    url: `comment/address/${address}`,
                }
            },
            transformResponse: (result) =>
                result.comments,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setComments(data));
                } catch (error) { console.log(error); }
            },
        }),
        getCommentsByCode: builder.query({
            query(code) {
                return {
                    url: `comment/code/${code}`,
                }
            },
            transformResponse: (result) =>
                result.comments,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setExploreComments(data));
                } catch (error) { console.log(error); }
            },
        }),
        deleteComment: builder.mutation({
            query(data) {
                return {
                    url: 'comment/delete/',
                    method: 'POST',
                    body: data
                }
            },
            transformResponse: (result) =>
                result.comment,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(deleteComment(data));
                } catch (error) { console.log(error); }
            },
        }),
    }),
});

export const {
    useSetCommentMutation,
    useGetCommentsByAddressQuery,
    useDeleteCommentMutation,
    useGetCommentsByCodeQuery
} = commentApi;