import { createSlice } from '@reduxjs/toolkit';

import { devmode } from "../../config";

const initialState = {
  contents: [],
  explore_contents: [],
}

const contentSlice = createSlice({
  name: 'contentSlice',
  initialState,
  reducers: {
    setContents: (state, action) => {
      if (devmode) console.log("setContents", action.payload)
      state.contents = action.payload;
    },
    setContent: (state, action) => {
      if (devmode) console.log("setContent", action.payload)
      let tmp_contents = [...state.contents];
      let content_index = -1;
      tmp_contents.map((content, index) => {
        if (content.ID == action.payload.ID) {
          content_index = index;
        }
      })
      if (content_index != -1) {
        tmp_contents[content_index] = action.payload;
      } else {
        tmp_contents.push(action.payload);
      }
      state.contents = tmp_contents;

      tmp_contents = [...state.explore_contents];
      content_index = -1;
      tmp_contents.map((content, index) => {
        if (content.ID == action.payload.ID) {
          content_index = index;
        }
      })
      if (content_index != -1) {
        tmp_contents[content_index] = action.payload;
      } else {
        tmp_contents.push(action.payload);
      }
      state.explore_contents = tmp_contents;
    },
    deleteContent: (state, action) => {
      if (devmode) console.log("deleteContent", action.payload)
      let tmp_contents = [...state.contents];
      let content_index = -1;
      tmp_contents.map((content, index) => {
        if (content.ID == action.payload.ID) {
          content_index = index;
        }
      })
      if (content_index != -1) {
        tmp_contents.splice(content_index, 1);
      }
      state.contents = tmp_contents;
    },
    setExploreContents: (state, action) => {
      if (devmode) console.log("setExploreContents", action.payload)
      state.explore_contents = action.payload;
    },
  },
})

export default contentSlice.reducer

export const { setContents, setContent, deleteContent, setExploreContents } = contentSlice.actions