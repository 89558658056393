import { useEffect } from "react";
import { useNavigate } from "react-router";

import { devmode } from "../config";

const HomePostItem = ({ asset }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (devmode) console.log("HomePostItem->", asset);
    }, []);

    return (
        <a href={`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${asset.Code}`} className="flex flex-row justify-center">
            <div className="w-full flex flex-col border border-gray-500 hover:cursor-pointer">
                <img src={asset.Banner ? asset.Banner : "/images/stk_banner_bg.png"} alt="" className="w-full h-[200px] object-cover" />
                {/* <div className="w-full flex flex-col gap-[10px] p-[10px] bg-white/10 backdrop-blur-sm">
                <div className="text-white font-bold text-[20px]">{asset.Title ? asset.Title : "None"}</div>
                <div className="text-white text-[15px]">By {asset.CreatorName}</div>
                <div className="text-white text-[15px]">Code: {asset.Code}</div>
            </div> */}
            </div>
        </a>
    );
};

export default HomePostItem;