import React from 'react';
import { Edit, Check } from "@mui/icons-material";
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { InfoOutlined } from "@mui/icons-material";

const FormField = ({
    label,
    value,
    isEditing,
    onEdit,
    onSave,
    onChange,
    multiline = false,
    error = false,
    errorText = '',
    tooltip = '',
    disabled = false,
    children = null
}) => {
    const fieldId = `field-${label.toLowerCase().replace(/\s+/g, '-')}`;
    
    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    };
    
    const handleSave = async () => {
        try {
            if (disabled || error) return;
            await onSave();
        } catch (error) {
            console.error("Failed to save field:", error);
            toast.error(error.message || "Failed to save field");
        }
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center gap-2">
                <label className="text-white/90 font-medium">{label}</label>
                {tooltip && (
                    <div className="text-white/40 hover:text-white/60 transition-colors cursor-help"
                         data-tooltip-id="form-tooltip"
                         data-tooltip-content={tooltip}>
                        <InfoOutlined sx={{ fontSize: "16px" }} />
                    </div>
                )}
            </div>

            {isEditing ? (
                <div className="space-y-4">
                    {children || (
                        <textarea
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            className="w-full bg-[#1a1a1a] border border-white/10 rounded-lg p-3 text-white resize-none focus:outline-none focus:border-blue-500"
                            rows={multiline ? 4 : 2}
                        />
                    )}
                    <div className="flex justify-end gap-2">
                        <button onClick={onEdit} className="px-4 py-2 text-white/60 hover:text-white transition-colors">
                            Cancel
                        </button>
                        <button onClick={onSave} className="flex items-center gap-2 px-4 py-2 bg-green-600/20 border border-green-500 rounded-lg text-white hover:bg-green-600/30 transition-colors">
                            <Check sx={{ fontSize: "20px" }} />
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-between items-start gap-4">
                    {value === "Not set" ? (
                        <p className="text-white/40 italic">Not set</p>
                    ) : (
                        <div 
                            className="w-full overflow-hidden text-white/90 prose prose-invert max-w-none"
                            dangerouslySetInnerHTML={{ __html: value }}
                        />
                    )}
                    <button 
                        onClick={onEdit}
                        className="shrink-0 flex items-center gap-1 px-2 py-1 bg-blue-600/20 border border-blue-500 rounded-lg text-white hover:bg-blue-600/30 transition-colors text-xs"
                    >
                        <Edit sx={{ fontSize: "16px" }} />
                        <span>Edit</span>
                    </button>
                </div>
            )}

            {error && errorText && (
                <p className="text-red-500 text-sm">{errorText}</p>
            )}
        </div>
    );
};

export default FormField;
