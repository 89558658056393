
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { devmode } from "../config";

const Assets = () => {

    const { code } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state_wallet = useSelector((state) => state.walletState);

    useEffect(() => {
        if (devmode) console.log("state_wallet:", state_wallet)
    }, [state_wallet.connected])

    return (
        <>
            <div className="w-full h-auto flex flex-col gap-[10px] lg:gap-[60px] bg-main_bg">
                <div className="relative w-full min-h-[100vh] flex flex-col gap-[40px] items-center px-[10px] z-0 pt-[120px] lg:pt-[100px] pb-[200px] lg:pb-[100px]">
                    <div className="text-white font-bold text-[20px] lg:text-[50px]">Assets</div>
                    <div className="w-5/6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-[20px] bg-white/10 backdrop-blur-sm rounded-xl p-[40px]">
                        {state_wallet.streamkey_nfts.map((nft) => {
                            return (
                                <div className="w-[250px] h-[400px] p-[10px] flex flex-col gap-[20px] bg-black-30 backdrop-blur-md rounded-lg border border-black/50">
                                    <img src={`${process.env.REACT_APP_IPFS_URL}/${nft.image.join().replace("ipfs://", "")}`} alt="" className="w-full h-1/2 object-cover" />
                                    <div className="w-full h-1/2 flex flex-col gap-[10px] overflow-hidden">
                                        <div className="text-white font-bold text-[20px]">{nft.name}</div>
                                        <textarea disabled={true} value={nft.assetId} className="h-full text-white text-[15px] p-[5px]" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Assets;