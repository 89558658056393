import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { EditorState,ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { uploadToLocalStorage } from "../cardano/market-api/helper";

import CircularProgress from '@mui/material/CircularProgress';

import ReactPlayer from 'react-player'

import { devmode } from "../config";
import PdfThumbnail from "./PdfViewer";

const CustomEditor = ({ type, url, onChange, onContentUploading, darkMode = false }) => {

    const ref = useRef(null);

    const [editorState, setEditorState] = useState(type == "text" && url != "" ? EditorState.createWithContent(ContentState.createFromBlockArray(
        htmlToDraft(url))) : EditorState.createEmpty());

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const state_wallet = useSelector((state) => state.walletState);

    useEffect(() => {
        console.log("CustomEditor ---> ", type, url);
    }, []);

    const onEditorStateChange = (new_editorState) => {
        setEditorState(new_editorState);
        onChange(draftToHtml(convertToRaw(new_editorState.getCurrentContent())));
        console.log("onEditorStateChange", draftToHtml(convertToRaw(new_editorState.getCurrentContent())));
    };

    const getItemContent = () => {
        switch (type) {
            case "image":
                return (
                    <img src={url} alt="" className="h-full w-full object-contain" />
                );
            case "text":
                return (
                    // <textarea value={url} disabled={true} className="h-full w-full text-white text-[10px] p-[5px] overflow-hidden" />
                    <p className="flex flex-col gap-[10px]" dangerouslySetInnerHTML={{ __html: url }} />
                );
            case "audio":
                return (
                    <img src="/images/audio.png" alt="" className="h-full w-full object-conitain" />
                );
            case "video":
                return (
                    <ReactPlayer url={url} width="100%" height="100%" loop playing />
                );
            
            case "pdf":
                return (
                    <PdfThumbnail url={url} pageIndex={0} />
                );
        }
    }

    const getItemAcceptType = () => {
        switch (type) {
            case "image":
                return "image/png, image/jpg, image/jpeg, image/webp";
            case "text":
                return "";
            case "audio":
                return "audio/mp3, audio/wav, audio/ogg";
            case "video":
                return "video/webm, video/mp4, video/wmv, video/avi, video/mpg";
            case "pdf":
                return "application/pdf";
        }
    }

    const onFileChange = async (e) => {
        if (e.target.files.length === 0) return;

        try {
            setIsUploading(true);
            setUploadProgress(0);
            onContentUploading(true);

            const file = e.target.files[0];
            const res = await uploadToLocalStorage(file, state_wallet.address, (progress) => {
                setUploadProgress(progress);
            });

            if (res.success) {
                const folderName = state_wallet.address.slice(-4);
                const filepath = `${process.env.REACT_APP_STREAMKEY_API_URL}/api/uploads/${folderName}/${res.data.filename}`;
                onChange(filepath);
            } else {
                toast.error('Upload failed. Please try again.');
            }
        } catch (error) {
            console.error('File upload error:', error);
            toast.error('Upload failed. Please try again.');
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            onContentUploading(false);
        }
    };

    const editorStyle = {
        backgroundColor: darkMode ? '#2a2a2a' : '#ffffff',
        color: darkMode ? '#ffffff' : '#000000',
        padding: '20px',
        borderRadius: '8px',
        border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #ddd',
        minHeight: '200px'
    };

    const toolbarStyle = {
        backgroundColor: darkMode ? '#1a1a1a' : '#f8f9fa',
        border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #ddd',
        borderRadius: '8px 8px 0 0',
    };

    return (
        <>
            {type == "text" ?
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper h-full flex flex-col gap-[5px]"
                    editorClassName="demo-editor h-full"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={editorStyle}
                    toolbarStyle={toolbarStyle}
                    toolbar={{
                        // ... existing toolbar options ...
                        // Add dark mode styles for dropdown menus, etc
                    }}
                /> :
                <div className="w-full h-full" ref={ref}>
                    {getItemContent()}
                    {isUploading ?
                        <div className="absolute top-0 left-0 w-full h-full bg-white/30 backdrop-blur-2xl flex justify-center items-center">
                            <CircularProgress />
                        </div> :
                        <></>
                    }
                    <input
                        type="file"
                        onChange={onFileChange}
                        multiple={false}
                        accept={getItemAcceptType()}
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer'
                        }}
                    />
                    {isUploading && (
                        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                            <div className="w-64 p-4 bg-white rounded-lg shadow-lg">
                                <div className="mb-2 text-center text-gray-700">
                                    Uploading... {uploadProgress}%
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div 
                                        className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                                        style={{ width: `${uploadProgress}%` }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

        </>
    );
};

export default CustomEditor;