import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Edit, Check, PostAdd, AddPhotoAlternate, AudioFile, Movie, PictureAsPdf, Add, Delete } from "@mui/icons-material";
import PostedItem from "../components/PostedItem";
import CustomEditor from "../components/CustomEditor";

import { setEditingId } from "../redux/features/walletSlice";

import { useSetUserMutation } from "../redux/apis/userApi";
import { useGetContentsByAddressQuery, useSetContentMutation } from "../redux/apis/contentApi";

import { uploadToLocalStorage } from "../cardano/market-api/helper";

import { devmode } from "../config";

import { Tooltip } from 'react-tooltip';
import FormField from '../components/FormField';
import 'react-tooltip/dist/react-tooltip.css';

import { formatStreamKeyCode, generateUniqueCode, createSafeFormData, validateStreamKeyUrl } from "../utils/safeDataHandling";

const retryWithBackoff = async (fn, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await fn();
        } catch (error) {
            if (i === maxRetries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
        }
    }
};

const prepareFormData = (baseUser, newData, fieldId) => {
    const formdata = new FormData();
    
    // Required fields
    formdata.append("id", baseUser.ID || '');
    formdata.append("address", baseUser.Address || '');
    formdata.append("code", baseUser.Code || '');
    formdata.append("is_popular", baseUser.IsPopular || false);
    
    // Optional fields with defaults
    const optionalFields = {
        banner: baseUser.Banner || '',
        badge: baseUser.Badge || '',
        title: baseUser.Title || '',
        creator_name: baseUser.CreatorName || '',
        description: baseUser.Description || '',
        nft_name: baseUser.NftName || '',
        policy_id: baseUser.PolicyId || '',
        buy_link: baseUser.BuyLink || '',
        page_code: baseUser.Code || ''
    };

    // Update specific field based on fieldId
    switch (fieldId) {
        case -1: optionalFields.title = newData.title; break;
        case -2: optionalFields.creator_name = newData.creatorName; break;
        case -3: optionalFields.description = newData.description; break;
        case -4: optionalFields.nft_name = newData.nftName; break;
        case -5: optionalFields.policy_id = newData.policyId; break;
        case -6: optionalFields.buy_link = newData.buyLink; break;
        case -7: 
            optionalFields.code = newData.pageCode;
            optionalFields.page_code = newData.pageCode;
            break;
    }

    // Append all fields to FormData
    Object.entries(optionalFields).forEach(([key, value]) => {
        formdata.append(key, value?.toString() || '');
    });

    return formdata;
};

const Create = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef(null);
    const refBanner = useRef(null);
    const refBadge = useRef(null);

    const state_wallet = useSelector((state) => state.walletState);
    const state_user = useSelector((state) => state.userState);
    const state_content = useSelector((state) => state.contentState);

    const [setUser] = useSetUserMutation();
    const [setContent] = useSetContentMutation();

    // Form state
    const [formData, setFormData] = useState({
        title: "",
        creatorName: "",
        description: "",
        nftName: "",
        policyId: "",
        buyLink: "",
        pageCode: ""
    });

    const [policyIssue, setPolicyIssue] = useState(false);

    const [showUploadContent, setShowUploadContent] = useState(false);

    const [isCheckingCode, setIsCheckingCode] = useState(false);
    const [codeError, setCodeError] = useState("");

    const [contentText, setContentText] = useState('Content');

    useGetContentsByAddressQuery(state_wallet.address);

    useEffect(() => {
        if (devmode) console.log("state_wallet:", state_wallet)

        dispatch(setEditingId(0));
    }, [state_wallet.connected])

    useEffect(() => {
        if (devmode) console.log("state_user:", state_user)

        if (state_user.user) {
            setFormData({
                title: state_user.user.Title || "",
                creatorName: state_user.user.CreatorName || "",
                description: state_user.user.Description || "",
                nftName: state_user.user.NftName || "",
                policyId: state_user.user.PolicyId || "",
                buyLink: state_user.user.BuyLink || "",
                pageCode: state_user.user.Code || ""
            });
        }
    }, [state_user.user]);

    const onEditPageItem = (id) => {
        if (devmode) console.log("onEditPageItem", id);
        if (state_wallet.editingId !== 0) {
            return;
        }
        dispatch(setEditingId(id));
    };

    const closeUploadContent = () => {
        setShowUploadContent(false);
    }

    const onTypeSelect = (type) => {
        if (!state_wallet.connected || !state_user.user?.Address) {
            toast.error("Please connect your wallet first");
            return;
        }

        if (devmode) console.log("onTypeSelect", type);
        setShowUploadContent(false);

        const formdata = new FormData();
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", "");
        formdata.append("description", "");
        formdata.append("content_type", type);
        formdata.append("url", "");

        setContent(formdata)
            .unwrap()
            .then((response) => {
                toast.success("Content added successfully");
            })
            .catch((error) => {
                console.error('Failed to add content:', error);
                toast.error("Failed to add content");
            });
    }

    const onBannerImageChange = async (e) => {
        if (devmode) console.log("onBannerImageChange", e);

        if (e.target.files.length != 0) {
            const res = await uploadToLocalStorage(e.target.files[0], state_wallet.address);
            if (devmode) console.log(res);
            if (res.success) {
                if (devmode) console.log(res.data.value.cid);
                const filepath = `${process.env.REACT_APP_STREAMKEY_API_URL}/api/uploads/${res.data.value.cid}`;
                
                const formdata = new FormData();
                formdata.append("id", state_user.user.ID);
                formdata.append("address", state_user.user.Address);
                formdata.append("code", state_user.user.Code);
                formdata.append("banner", filepath);
                formdata.append("badge", state_user.user.Badge);
                formdata.append("title", state_user.user.Title);
                formdata.append("creator_name", state_user.user.CreatorName);
                formdata.append("description", state_user.user.Description);
                formdata.append("is_popular", state_user.user.IsPopular);
                formdata.append("nft_name", state_user.user.NftName);
                formdata.append("policy_id", state_user.user.PolicyId);
                formdata.append("buy_link", state_user.user.BuyLink);
                formdata.append("page_code", formData.pageCode);
                setUser(formdata);
            }
        }
    }

    const onBadgeImageChange = async (e) => {
        if (devmode) console.log("onBadgeImageChange", e);

        if (e.target.files.length !== 0) {
            const res = await uploadToLocalStorage(e.target.files[0], state_wallet.address);
            if (devmode) console.log(res);
            if (res.success) {
                if (devmode) console.log(res.data.value.cid);
                const filepath = `${process.env.REACT_APP_STREAMKEY_API_URL}/api/uploads/${res.data.value.cid}`;

                if (!state_user?.user) {
                    toast.error('User information not available. Please try again.');
                    return;
                }

                const formdata = new FormData();
                formdata.append("id", state_user.user.ID || '');
                formdata.append("address", state_user.user.Address || '');
                formdata.append("code", state_user.user.Code || '');
                formdata.append("banner", state_user.user.Banner || '');
                formdata.append("badge", filepath);
                formdata.append("title", state_user.user.Title || '');
                formdata.append("creator_name", state_user.user.CreatorName || '');
                formdata.append("description", state_user.user.Description || '');
                formdata.append("is_popular", state_user.user.IsPopular || false);
                formdata.append("nft_name", state_user.user.NftName || '');
                formdata.append("policy_id", state_user.user.PolicyId || '');
                formdata.append("buy_link", state_user.user.BuyLink || '');
                formdata.append("page_code", formData.pageCode || '');
                setUser(formdata);
            }
        }
    }

    const onChangeTitle = (new_title) => {
        setFormData(prev => ({
            ...prev,
            title: new_title
        }));
    }

    const onChangeCreatorName = (new_creator_name) => {
        setFormData(prev => ({
            ...prev,
            creatorName: new_creator_name
        }));
    }

    const onChangeDescription = (new_description) => {
        setFormData(prev => ({
            ...prev,
            description: new_description
        }));
    }



    const handleFieldChange = (field, value) => {
        console.log("Field change detected:", field, "=", value);
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleStreamKeyUrlValidation = async (value) => {
        const formattedCode = formatStreamKeyCode(value);
        
        if (formattedCode.length >= 3) {
            setIsCheckingCode(true);
            try {
                if (formattedCode === state_user.user?.Code?.toLowerCase()) {
                    setCodeError("");
                    return;
                }

                const response = await fetch(
                    `${process.env.REACT_APP_STREAMKEY_API_URL}/user/check-code/${formattedCode}`
                );
                const data = await response.json();
                
                setCodeError(data.exists ? "This StreamKey URL is already taken" : "");
            } catch (error) {
                console.error("Error checking code availability:", error);
                setCodeError("Error checking StreamKey URL availability");
            } finally {
                setIsCheckingCode(false);
            }
        } else {
            setCodeError("");
        }
    };

    const handleFieldSave = async (id) => {
        if (!state_user.user || !state_wallet.address) {
            toast.error("Please connect your wallet first");
            return;
        }

        try {
            // Create form data with all existing user data
            const formdata = createSafeFormData(state_user.user);
            formdata.set("address", state_wallet.address);

            // Update the specific field
            switch (id) {
                case -1:
                    formdata.set("title", formData.title);
                    break;
                case -2:
                    formdata.set("creator_name", formData.creatorName);
                    break;
                case -3:
                    formdata.set("description", formData.description);
                    break;
                case -4:
                    formdata.set("nft_name", formData.nftName);
                    break;
                case -5:
                    if (policyIssue) {
                        throw new Error("Invalid policy ID");
                    }
                    formdata.set("policy_id", formData.policyId);
                    break;
                case -6:
                    formdata.set("buy_link", formData.buyLink);
                    break;
                case -7:
                    const validCode = formData.pageCode?.trim();
                    if (!validCode) {
                        throw new Error("StreamKey URL cannot be empty");
                    }
                    formdata.set("code", validCode);
                    formdata.set("page_code", validCode);
                    break;
                default:
                    throw new Error("Invalid field ID");
            }

            // Make API call
            const result = await setUser(formdata).unwrap();
            
            if (devmode) console.log("API Response:", result);

            // Update Redux state with serializable data
            if (result?.user) {
                const serializedUser = {
                    ID: result.user.id || result.user.ID,
                    Address: result.user.address || result.user.Address,
                    Code: result.user.code || result.user.Code,
                    Banner: result.user.banner || result.user.Banner,
                    Badge: result.user.badge || result.user.Badge,
                    Title: result.user.title || result.user.Title,
                    CreatorName: result.user.creator_name || result.user.CreatorName,
                    Description: result.user.description || result.user.Description,
                    IsPopular: result.user.is_popular || result.user.IsPopular,
                    NftName: result.user.nft_name || result.user.NftName,
                    PolicyId: result.user.policy_id || result.user.PolicyId,
                    BuyLink: result.user.buy_link || result.user.BuyLink
                };
                
                dispatch({ type: 'userState/setUser', payload: serializedUser });
            }
            
            dispatch(setEditingId(0));
            toast.success("Updated successfully!");
            return result;

        } catch (error) {
            console.error("Save failed:", error);
            toast.error(error.message || "Failed to update field");
            throw error;
        }
    };

    const handlePageCodeChange = async (e) => {
        const code = e.target.value;
        setFormData(prev => ({...prev, pageCode: code}));
        
        if (code.length >= 3) {
            try {
                await validateStreamKeyUrl(code, state_user.user?.Code);
                setCodeError("");
            } catch (error) {
                setCodeError(error.message);
            }
        } else {
            setCodeError("");
        }
    };

    const validateField = (id, value) => {
        switch (id) {
            case -5: // Policy ID
                return value !== process.env.REACT_APP_GEM_POLICY_ID && 
                       value !== process.env.REACT_APP_POWER_GEM_POLICY_ID;
            case -7: // StreamKey URL
                return value && value.length > 0;
            default:
                return true;
        }
    };

    const onSave = async () => {
        try {
            if (!state_user.user || !state_wallet.address) {
                toast.error("Please connect your wallet first");
                return;
            }

            // Create form data with all current values
            const formdata = new FormData();
            formdata.append("id", state_user.user.ID);
            formdata.append("address", state_wallet.address);
            formdata.append("code", formData.pageCode || state_user.user.Code);
            formdata.append("banner", state_user.user.Banner || "");
            formdata.append("badge", state_user.user.Badge || "");
            formdata.append("title", formData.title);
            formdata.append("creator_name", formData.creatorName);
            formdata.append("description", formData.description);
            formdata.append("is_popular", state_user.user.IsPopular || false);
            formdata.append("nft_name", formData.nftName);
            formdata.append("policy_id", formData.policyId);
            formdata.append("buy_link", formData.buyLink);
            formdata.append("page_code", formData.pageCode || state_user.user.Code);

            // Save all content
            const result = await setUser(formdata).unwrap();
            
            if (result?.user) {
                toast.success("Content saved successfully!");
            } else {
                throw new Error("Failed to save content");
            }

        } catch (error) {
            console.error("Error saving content:", error);
            toast.error(error.message || "Failed to save content");
        }
    };

    const handleContentChange = (newContent) => {
        if (!state_wallet.connected || !state_wallet.address) {
            toast.error("Please connect your wallet first");
            return;
        }

        const formdata = new FormData();
        formdata.append("id", "");
        formdata.append("creator_address", state_wallet.address);
        formdata.append("title", "Title");
        formdata.append("description", "Description");
        formdata.append("content_type", "text");
        formdata.append("url", newContent);
        setContent(formdata);
    };

    return (
        <>
            <div className="w-full min-h-screen bg-main_bg">
                <div className="relative w-full min-h-screen flex flex-col gap-[40px] items-center px-[10px] z-0 pt-[120px] lg:pt-[100px] pb-[200px] lg:pb-[100px]">
                    <div className="text-white font-bold text-[30px] lg:text-[50px] text-center">Create your StreamKey!</div>
                    
                    {/* Form Container */}
                    <div className="w-full max-w-4xl mx-auto bg-[#1a1a1a]/50 backdrop-blur-md rounded-xl p-4 md:p-8 shadow-2xl">
                        {/* Page URL Section */}
                        {state_user.user && (
                            <div className="mb-6 md:mb-8 bg-white/10 rounded-lg p-3 md:p-4">
                                <div className="flex flex-col md:flex-row gap-3 md:gap-4 items-start md:items-center justify-between">
                                    <h2 className="text-white font-semibold text-base md:text-lg">Your StreamKey URL</h2>
                                    {state_wallet.editingId != -7 ? (
                                        <div className="flex items-center gap-2 w-full md:w-auto overflow-x-auto">
                                            <span className="text-white/80 text-sm whitespace-nowrap">
                                                {`${process.env.REACT_APP_STREAMKEY_URL}/StreamKey/${state_user.user.Code}`}
                                            </span>
                                            <Edit 
                                                className="text-white hover:text-blue-400 transition-colors cursor-pointer flex-shrink-0" 
                                                sx={{ fontSize: "24px" }} 
                                                onClick={() => onEditPageItem(-7)}
                                                data-tooltip-id="edit-url"
                                                data-tooltip-content="Edit your StreamKey URL"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-2 w-full md:w-auto">
                                            <span className="text-white/80 text-sm whitespace-nowrap">{process.env.REACT_APP_STREAMKEY_URL}/StreamKey/</span>
                                            <input
                                                type="text"
                                                value={formData.pageCode}
                                                onChange={handlePageCodeChange}
                                                placeholder="Enter your StreamKey URL"
                                                className={`form-control ${codeError ? 'is-invalid' : ''}`}
                                            />
                                            {codeError && <div className="invalid-feedback">{codeError}</div>}
                                            <Check
                                                className="text-green-400 hover:text-green-300 transition-colors cursor-pointer flex-shrink-0"
                                                sx={{ fontSize: "24px" }}
                                                onClick={() => handleFieldSave(-7)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Banner & Profile Section */}
                        <div className="relative w-full h-[300px] md:h-[400px] rounded-xl overflow-hidden mb-24">
                            <img 
                                src={state_user.user?.Banner || "/images/stk_banner_bg.png"} 
                                alt="Banner" 
                                className="w-full h-full object-cover"
                            />
                            <div ref={refBanner} className="absolute top-4 right-4 bg-black/30 rounded-full p-2 group hover:bg-black/50 transition-colors">
                                <Edit 
                                    className="text-white group-hover:scale-110 transition-transform" 
                                    sx={{ fontSize: "24px" }}
                                    data-tooltip-id="edit-banner"
                                    data-tooltip-content="Upload banner image (Recommended: 1920x400px)"
                                />
                                <input
                                    type="file"
                                    onChange={onBannerImageChange}
                                    multiple={false}
                                    accept="image/png, image/jpg, image/webp"
                                    className="absolute inset-0 opacity-0 cursor-pointer"
                                />
                            </div>
                            
                            {/* Profile Badge */}
                            <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
                                <div ref={refBadge} className="relative group">
                                    <img 
                                        src={state_user.user?.Badge || "/images/stk_icon.png"} 
                                        alt="Profile" 
                                        className="w-[120px] h-[120px] rounded-full border-4 border-white/10 object-cover"
                                    />
                                    <div className="absolute inset-0 bg-black/30 rounded-full opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                                        <Edit 
                                            className="text-white" 
                                            sx={{ fontSize: "30px" }}
                                            data-tooltip-id="edit-profile"
                                            data-tooltip-content="Upload profile image (1:1 ratio recommended)"
                                        />
                                        <input
                                            type="file"
                                            onChange={onBadgeImageChange}
                                            multiple={false}
                                            accept="image/png, image/jpg, image/webp"
                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Form Fields Container */}
                        <div className="grid grid-cols-1 gap-6">
                            {/* Title */}
                            <FormField
                                label="Title"
                                value={formData.title}
                                isEditing={state_wallet.editingId === -1}
                                onEdit={() => dispatch(setEditingId(-1))}
                                onSave={() => handleFieldSave(-1)}
                                onChange={(value) => handleFieldChange('title', value)}
                                tooltip="The title of your StreamKey"
                            />

                            {/* Creator Name */}
                            <FormField
                                label="Creator Name"
                                value={formData.creatorName}
                                isEditing={state_wallet.editingId === -2}
                                onEdit={() => dispatch(setEditingId(-2))}
                                onSave={() => handleFieldSave(-2)}
                                onChange={(value) => handleFieldChange('creatorName', value)}
                                tooltip="Your name as a creator"
                            />

                            {/* Description */}
                            <FormField
                                label="Description"
                                value={formData.description}
                                isEditing={state_wallet.editingId === -3}
                                onEdit={() => dispatch(setEditingId(-3))}
                                onSave={() => handleFieldSave(-3)}
                                onChange={(value) => handleFieldChange('description', value)}
                                multiline
                                tooltip="Describe your StreamKey and what viewers can expect"
                            >
                                {state_wallet.editingId === -3 && (
                                    <CustomEditor
                                        type="text"
                                        url={formData.description}
                                        onChange={(value) => handleFieldChange('description', value)}
                                        onContentUploading={() => {}}
                                        darkMode={true}
                                    />
                                )}
                            </FormField>

                            {/* NFT Name Required */}
                            <FormField
                                label="NFT Name Required"
                                value={formData.nftName}
                                isEditing={state_wallet.editingId === -4}
                                onEdit={() => dispatch(setEditingId(-4))}
                                onSave={() => handleFieldSave(-4)}
                                onChange={(value) => handleFieldChange('nftName', value)}
                                tooltip="Name of the NFT required to access your content"
                            />

                            {/* NFT Policy Key */}
                            <FormField
                                label="NFT Policy Key Required"
                                value={formData.policyId}
                                isEditing={state_wallet.editingId === -5}
                                onEdit={() => dispatch(setEditingId(-5))}
                                onSave={() => handleFieldSave(-5)}
                                onChange={(value) => handleFieldChange('policyId', value)}
                                error={policyIssue}
                                errorText="Policy shouldn't be Gems or PowerGems"
                                tooltip="The policy ID of the NFT required to access your content"
                            />

                            {/* Buy Link */}
                            <FormField
                                label="Link to buy or mint NFT set"
                                value={formData.buyLink}
                                isEditing={state_wallet.editingId === -6}
                                onEdit={() => dispatch(setEditingId(-6))}
                                onSave={() => handleFieldSave(-6)}
                                onChange={(value) => handleFieldChange('buyLink', value)}
                                tooltip="Where can viewers buy your NFT?"
                            />

                            {/* StreamKey URL */}
                            <FormField
                                label="StreamKey URL"
                                value={formData.pageCode}
                                isEditing={state_wallet.editingId === -7}
                                onEdit={() => dispatch(setEditingId(-7))}
                                onSave={() => handleFieldSave(-7)}
                                onChange={(value) => handleFieldChange('pageCode', value)}
                                disabled={isCheckingCode}
                                error={!!codeError}
                                helperText={codeError || (isCheckingCode ? "Checking URL availability..." : "")}
                                data-tooltip-id="streamkey-url-tooltip"
                                data-tooltip-content="Create a unique URL for your StreamKey page. This will be how others find your content."
                                tooltip="Create a unique URL for your StreamKey page. This will be how others find your content."
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                showUploadContent ? (
                    <div className="fixed backdrop-filter backdrop-blur-sm bg-backdrop flex items-center justify-center overflow-auto z-50 inset-0">
                        <div
                            className="relative bg-[#1a1a1a]/50 backdrop-blur-md rounded-xl shadow-xl px-4 py-10 max-w-xl w-11/12 md:w-full"
                            ref={ref}
                        >
                            <div className="text-center mb-7">
                                <h1 className="text-white mb-10 font-bold text-3xl">
                                    Add Content
                                </h1>
                            </div>{" "}
                            <button
                                type="button"
                                onClick={closeUploadContent}
                                className="absolute text-2xl px-2.5 text-white top-3 right-3 hover:opacity-100 opacity-70"
                            >
                                <i className="fas fa-times" />
                            </button>{" "}
                            <div className="flex justify-start md:justify-center gap-[10px] overflow-auto">
                                <div onClick={(e) => { onTypeSelect("text") }} 
                                    className="relative flex flex-col gap-[20px] hover:cursor-pointer"
                                    data-tooltip-id="text-content-tooltip"
                                    data-tooltip-content="Add text content like articles, stories, or announcements">
                                    <div className="h-[100px] w-[100px] bg-transparent border-2 rounded-lg border-dashed border-white flex flex-col justify-center items-center text-white hover:cursor-pointer">
                                        <PostAdd sx={{ fontSize: "30px" }} />
                                        <div className="text-[15px]">Add Text</div>
                                    </div>
                                </div>
                                <div onClick={(e) => { onTypeSelect("image") }} className="relative flex flex-col gap-[10px] hover:cursor-pointer">
                                    <div className="h-[100px] w-[100px] bg-transparent border-2 rounded-lg border-dashed border-white flex flex-col justify-center items-center text-white hover:cursor-pointer">
                                        <AddPhotoAlternate sx={{ fontSize: "30px" }} />
                                        <div className="text-[15px]">Upload Image</div>
                                    </div>
                                </div>
                                <div onClick={(e) => { onTypeSelect("audio") }} className="relative flex flex-col gap-[10px] hover:cursor-pointer">
                                    <div className="h-[100px] w-[100px] bg-transparent border-2 rounded-lg border-dashed border-white flex flex-col justify-center items-center text-white hover:cursor-pointer">
                                        <AudioFile sx={{ fontSize: "30px" }} />
                                        <div className="text-[15px]">Upload Audio</div>
                                    </div>
                                </div>
                                <div onClick={(e) => { onTypeSelect("video") }} className="relative flex flex-col gap-[10px] hover:cursor-pointer">
                                    <div className="h-[100px] w-[100px] bg-transparent border-2 rounded-lg border-dashed border-white flex flex-col justify-center items-center text-white hover:cursor-pointer">
                                        <Movie sx={{ fontSize: "30px" }} />
                                        <div className="text-[15px]">Upload Video</div>
                                    </div>
                                </div>
                                <div onClick={(e) => { onTypeSelect("pdf") }} className="relative flex flex-col gap-[10px] hover:cursor-pointer">
                                    <div className="h-[100px] w-[100px] bg-transparent border-2 rounded-lg border-dashed border-white flex flex-col justify-center items-center text-white hover:cursor-pointer">
                                        <PictureAsPdf sx={{ fontSize: "30px" }} />
                                        <div className="text-[15px]">Upload PDF</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <></>
            }
            {/* Tooltips */}
            <Tooltip id="edit-banner" content="Upload a banner image for your StreamKey" place="top" className="tooltip-custom" />
            <Tooltip id="edit-profile" content="Upload a profile picture" place="top" className="tooltip-custom" />
            <Tooltip id="streamkey-url-tooltip" content="Create a unique URL for your StreamKey page" place="top" className="tooltip-custom" />
            <Tooltip id="text-content-tooltip" content="Add text content like articles, stories, or announcements" place="top" className="tooltip-custom" />
            <Tooltip id="image-content-tooltip" content="Upload images in PNG, JPG, JPEG, or WebP format" place="top" className="tooltip-custom" />
            <Tooltip id="audio-content-tooltip" content="Upload audio files in MP3, WAV, or OGG format" place="top" className="tooltip-custom" />
            <Tooltip id="video-content-tooltip" content="Upload videos in MP4, WebM, or AVI format" place="top" className="tooltip-custom" />
            <Tooltip id="pdf-content-tooltip" content="Upload PDF documents" place="top" className="tooltip-custom" />
            <Tooltip id="add-content" content="Add new content to your StreamKey" place="top" className="tooltip-custom" />
            <Tooltip id="content-section-tooltip" content="View and manage all your StreamKey content" place="top" className="tooltip-custom" />
            <Tooltip id="edit-title" content="Edit your StreamKey title" place="top" className="tooltip-custom" />
            <Tooltip id="edit-creator-name" content="Edit your creator name" place="top" className="tooltip-custom" />
            <Tooltip id="edit-description" content="Add a description about your StreamKey" place="top" className="tooltip-custom" />
            <Tooltip id="edit-nft-name" content="Set your NFT collection name" place="top" className="tooltip-custom" />
            <Tooltip id="edit-policy-id" content="Set your NFT policy ID for content access" place="top" className="tooltip-custom" />
            <Tooltip id="edit-buy-link" content="Add a link where viewers can buy your NFTs" place="top" className="tooltip-custom" />
            <Tooltip id="edit-page-code" content="Customize your StreamKey URL" place="top" className="tooltip-custom" />
            {/* Content Section */}
            <div className="w-full max-w-4xl mx-auto bg-gradient-to-b from-[#1a1a1a] to-[#2a2a2a] backdrop-blur-md rounded-xl p-8 shadow-2xl mt-8 border border-white/10">
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h2 className="text-white text-2xl font-semibold mb-2">Content</h2>
                        <p className="text-white/60 text-sm">Manage your StreamKey content here</p>
                    </div>
                    <button
                        onClick={() => {
                            if (!state_wallet.connected) {
                                toast.error("Please connect your wallet first");
                                return;
                            }
                            setShowUploadContent(true);
                        }}
                        className="bg-blue-600/20 hover:bg-blue-600/30 text-white px-6 py-3 rounded-lg transition-colors flex items-center gap-2 border border-blue-500"
                        data-tooltip-id="add-content"
                        data-tooltip-content="Add new content like text, images, audio, video, or PDFs to your StreamKey"
                    >
                        <Add sx={{ fontSize: "20px" }} />
                        <span>Add Content</span>
                    </button>
                </div>

                {/* Content Grid */}
                <div className="grid grid-cols-1 gap-6">
                    {state_content.contents?.map((content) => (
                        <PostedItem
                            key={content.ID}
                            id={content.ID}
                            title={content.Title}
                            description={content.Description}
                            policy_id={content.PolicyId}
                            type={content.ContentType}
                            url={content.Url}
                            edit_flag={true}
                        />
                    ))}
                </div>
            </div>
            {/* Add all tooltips */}
            <Tooltip id="edit-banner" />
            <Tooltip id="edit-profile" />
            <Tooltip id="streamkey-url-tooltip" />
            <Tooltip id="text-content-tooltip" />
            <Tooltip id="image-content-tooltip" />
            <Tooltip id="audio-content-tooltip" />
            <Tooltip id="video-content-tooltip" />
            <Tooltip id="pdf-content-tooltip" />
            <Tooltip id="add-content" />
            <Tooltip id="content-section-tooltip" />
            <Tooltip id="edit-title" />
            <Tooltip id="edit-creator-name" />
            <Tooltip id="edit-description" />
            <Tooltip id="edit-nft-name" />
            <Tooltip id="edit-policy-id" />
            <Tooltip id="edit-buy-link" />
            <Tooltip id="edit-page-code" />
        </>
    );
};

export default Create;