import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  explore_user: null,
  contents: [],
  status: 'idle',
  popular_users: [],
  featured_users: [],
  walletProvider: null,
  walletAddress: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload.user;
      state.contents = action.payload.contents;
      state.status = action.payload.status;
      state.walletProvider = action.payload.walletProvider;
      state.walletAddress = action.payload.walletAddress;
    },
    setExploreUser: (state, action) => {
      state.explore_user = action.payload;
    },
    setPopularUsers: (state, action) => {
      state.popular_users = action.payload;
    },
    setFeaturedUsers: (state, action) => {
      state.featured_users = action.payload;
    },
    clearUserInfo: (state) => {
      state.user = null;
      state.explore_user = null;
      state.contents = [];
      state.status = 'idle';
      state.popular_users = [];
      state.featured_users = [];
      state.walletProvider = null;
      state.walletAddress = null;
    }
  }
});

export const { 
  setUser, 
  setUserInfo, 
  setExploreUser, 
  setPopularUsers,
  setFeaturedUsers,
  clearUserInfo 
} = userSlice.actions;

export default userSlice.reducer;